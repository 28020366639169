import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import MetaCards from "../../components/common/meta-cards"
import Hero from "../../components/article/hero"
import FeaturedSection from "../../components/article/feature"
import ArticleContent from "../../components/article/content"
import OfferedServicesSurgeries from "../../components/shared/offered_services_surgeries"
import HelpfulLinks from "../../components/shared/helpful_links"
import Testimonials from "../../components/shared/testimonials"
import FAQs from "../../components/article/faq"
import TipsFromTeam from "../../components/shared/tips_from_team"
import Visit from "../../components/shared/visit"

const StandardPage = props => {
  // useEffect(() => {
  //   if (window.location.href.indexOf("surgery-overview") > -1) {
  //     document.querySelector(
  //       ".fullWidthWeOffer .row"
  //     ).innerHTML += `<a class="btn d-btn" href="/surgeries/">View All Surgeries</a>`
  //   }
  // }, [])

  return (
    <Layout>
      <Seo title={props.data.articles.title} />
      <MetaCards data={props.data.articles.webMeta} />
      <div className="servicesPage">
        <Hero articles={props.data.articles} />
        <FeaturedSection articles={props.data.articles} />
        {props.data.articles.content.map(item => (
          <ArticleContent key={item.id} item={item} />
        ))}
        {/* <div className={props.data.articles.hideOfferedServicesLinks && "hideFAQ"}>
          <OfferedServicesSurgeries
          offer={props.data.articles.surgeriesServicesWeOffer}
        /> 
        </div>*/}
        <Testimonials data={props.data.articles} about={true} />
        <TipsFromTeam data={props.data.articles.tipsFromTeam} services={true} />
        <Visit data={props.data.articles.scheduleAVisit} />
      </div>
    </Layout>
  )
}

export default StandardPage

export const query = graphql`
  query ($slug: String!) {
    articles: contentfulServicesSurgeriesArticlesShort(slug: { eq: $slug }) {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      id
      image {
        file {
          url
        }
      }
      category
      title
      slug
      excerpt {
        excerpt
      }
      date
      keywords
      introText {
        raw
      }
      rightSideBar {
        title
        links {
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      newPatients {
        image {
          file {
            url
          }
        }
        title
        content {
          content
        }
        linkLabel
        linkUrl
      }
      content {
        id
        fullWidth
        imageToLeft

        content {
          raw
          references {
            contentful_id
            __typename
            url
          }
        }
      }
      testimonialTitle
      testimonials {
        id
        testimonial {
          testimonial
        }
        client
      }
      tipsFromTeam {
        title
        content
        tips {
          id
          label
          shortDescription
          url
          image {
            file {
              url
            }
          }
        }
      }

      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
      }
    }
  }
`
